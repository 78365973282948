define("m12-2020/templates/components/screw-tablerow", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "xtzIrIvw",
    "block": "{\"symbols\":[\"param\"],\"statements\":[[4,\"each\",[[23,0,[\"paramliste\"]]],null,{\"statements\":[[0,\"  \"],[7,\"td\",false],[12,\"class\",[29,[\"VM_Okay\",[24,[\"item\",\"VM_Okay\"]]]]],[3,\"on\",[\"mouseover\",[28,\"fn\",[[23,0,[\"toggleTip\"]],[23,0,[]]],null]]],[3,\"on\",[\"mouseout\",[28,\"fn\",[[23,0,[\"toggleTip\"]],[23,0,[]]],null]]],[3,\"on\",[\"click\",[28,\"fn\",[[23,0,[\"setSelectedScrew\"]],[23,0,[\"item\",\"ArtNr\"]],[23,0,[\"item\",\"VM_Okay\"]],[23,0,[\"item\",\"ProductInfo\"]],[23,0,[\"item\",\"CAD\"]]],null]]],[8],[0,\"\\n      \"],[1,[28,\"get\",[[24,[\"item\"]],[23,1,[]]],null],false],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "m12-2020/templates/components/screw-tablerow.hbs"
    }
  });

  _exports.default = _default;
});