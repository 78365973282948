define("m12-2020/controllers/verbindungsmittel", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = Ember.Controller.extend((_obj = {
    // schraubenliste: [],
    calcid: "",
    tooltiptext: "",
    loading: true,
    showinvalidscrews: false,
    validScrewSelected: false,
    infoText: "",
    keineSchraubenliste: false,
    jstreeStateOptionHash: {
      'key': ''
    },
    plugins: 'state',
    selectedNodes: "",
    selectedSrewArtNr: null,
    applicationController: Ember.inject.controller('application'),
    lasteinwirkungController: Ember.inject.controller('lasteinwirkung'),
    downloadcenterController: Ember.inject.controller('downloadcenter'),
    ergebnisController: Ember.inject.controller('ergebnis'),
    superController: Ember.inject.controller('supercontroller'),
    kompaktergebnisHeight: '50px',
    init: function () {
      this._super();

      if (!this.get('lasteinwirkungController').get('lasteinwirkungInvalid') || !this.get('bauteileController').get('bauteileInvalid')) {
        this.transitionToRoute('/projektdaten');
      } else {
        console.log("lasteinwirkung ist valid");
      }
    },
    initialisierung: function () {
      var self = this;
      self.setKompaktergebnisHeight();
    },
    watchInvalidScrewsCombo: Ember.observer('showinvalidscrews', function () {
      var self = this;
      var core = undefined;
      var schraubenliste = null;

      if (self.showinvalidscrews) {
        console.log("hier werden die unzulässigen schrauben eingeblendet.");
        schraubenliste = self.get('lasteinwirkungController').get('schraubenliste');
        core = treetools.buildTree(schraubenliste, true);
        self.set('schraubenliste', core);
      } else {
        console.log("und hier wieder aus.");
        schraubenliste = self.get('lasteinwirkungController').get('schraubenliste');
        core = treetools.buildTree(schraubenliste, false);
        self.set('schraubenliste', core);
      }
    }),

    controllerSetSelectedScrew(_artNr, _valid, _produktInfo, _cad) {
      console.log("setInvalidScrews");
      console.log(_artNr + " ::: " + _valid);
      this.set('selectedSrewArtNr', _artNr);
      var self = this;
      var x3d = self.get('applicationController').get('model').x3ddefault.objectAt(0);

      if (_valid === "True") {
        self.get('applicationController').set('verbindungsmittelInvalid', false);
        self.get('applicationController').set('schraubenInfo', _produktInfo);
        self.get('applicationController').set('schraubenCAD', _cad);
        self.set('validScrewSelected', true);
        self.get('downloadcenterController').set('schraubenInfo', _produktInfo);
        self.get('downloadcenterController').set('schraubenCAD', _cad);
        self.get('downloadcenterController').set('validScrewSelected', true);
        self.get('ergebnisController').set('pdfErstellt', false);
        this.ergebnisgrafik(); // x3d.set('ergebnisGeladen', true);
      } else {
        self.get('applicationController').set('verbindungsmittelInvalid', true);
        self.set('validScrewSelected', false);
        self.get('downloadcenterController').set('validScrewSelected', false);
        var bauteile = self.get('applicationController').get('model').x3ddefault.objectAt(0).get('bauteile');
        x3d.set('aktuellerSchraubenTyp', 'standardSchraube');
        x3d.set('ergebnisGeladen', false);
        x3d.set('transformHelper', !x3d.get('transformHelper'));
      }

      x3d.set('schraubenlisteAktualisiert', !x3d.get('schraubenlisteAktualisiert'));
    },

    reload: true,
    ergebnisgrafik: function () {
      console.log("ergebnisgrafik");
      var self = this; // var selectedScrew = self.selectedNodes[0].data.ArtNr;

      var selectedScrew = self.get('selectedSrewArtNr');
      var cidGrafik = self.calcid;
      var store = self.get('applicationController').get('store');
      var arrayHelper = [];
      var x3d = self.get('applicationController').get('model').x3ddefault.objectAt(0); // alte Schrauben "Verbindung (has-many)" aus x3ddefault.get('bauteile') löschen

      x3d.get('bauteile').forEach(function (item) {
        if (item.get('typ') === 'verbindungsmittel' || item.get('typ') === 'masskette' || item.get('typ') === 'platte') {
          arrayHelper.pushObject(item);
        }
      });
      arrayHelper.forEach(function (item) {
        // x3d.get('bauteile').objectAt(0).get('translations').removeObject(x3d.get('bauteile').objectAt(0).get('translations').findBy('id', item.id));
        // x3d.get('bauteile').objectAt(0).get('rotations').removeObject(x3d.get('bauteile').objectAt(0).get('rotations').findBy('id', item.id));
        // x3d.get('bauteile').objectAt(0).get('richtungsvektor').removeObject(x3d.get('bauteile').objectAt(0).get('richtungsvektor').findBy('id', item.id));
        // x3d.get('bauteile').objectAt(0).get('appearances').removeObject(x3d.get('bauteile').objectAt(0).get('appearances').findBy('id', item.id));
        // x3d.get('bauteile').objectAt(0).get('diffusecolors').removeObject(x3d.get('bauteile').objectAt(0).get('screwProperties').findBy('id', item.id));
        // x3d.get('bauteile').objectAt(0).get('screwProperties').removeObject(x3d.get('bauteile').objectAt(0).get('translations').findBy('id', item.id));
        x3d.get('bauteile').removeObject(item); // x3d.get('bauteile').unloadRecord(item);
        // item.deleteRecord();
        // item.isDeleted;

        item.destroyRecord().then(() => {
          item.unloadRecord(); // the Travis fix goes here
        });
      });
      const bauteile = store.peekRecord('bauteile', 1);

      if (bauteile) {
        store.unloadRecord(bauteile);
      }

      if (self.get('reload') === true) {
        var server = self.get('applicationController').get('server');

        _jquery.default.ajax({
          type: "POST",
          url: server + "m12/DE/getGrafik/",
          data: {
            cid: cidGrafik,
            artnr: selectedScrew
          }
        }).done(function (data) {
          console.log('Daten der ausgewählten Schraube');
          console.log(data);
          var dataObject = JSON.parse(data);
          var xWerte = [];
          var yWerte = [];
          var zWerte = [];
          dataObject.bauteile.forEach(function (item) {
            var newID = item.id;

            if (item.typ === "verbindungsmittel") {
              newID = "schraubeStandard1";
            } else if (item.typ === "platte") {
              newID = "holz";
            } else if (item.typ === "masskette") {
              newID = "masskette";
            }

            var translation, platte, richtungsvektor, ansichtsvektor1, ansichtsvektor2, ansichtsvektor3, orthovektor, verbindungsmittel, masskette, lagewinkel, diffusecolor, appearance;
            var punkte = Ember.A([]);

            if (typeof dataObject.punkte.findBy('id', dataObject.bauteile.findBy('id', item.id).point.objectAt(0)) === "undefined") {// console.log('PUNKTE für Objekt nicht vorhanden');
            } else {
              // xWerte.push(Number(dataObject.punkte.findBy('id', dataObject.bauteile.findBy('id', item.id).point.objectAt(0)).x));
              // yWerte.push(Number(dataObject.punkte.findBy('id', dataObject.bauteile.findBy('id', item.id).point.objectAt(0)).y));
              // zWerte.push(Number(dataObject.punkte.findBy('id', dataObject.bauteile.findBy('id', item.id).point.objectAt(0)).z));
              dataObject.bauteile.findBy('id', item.id).point.forEach(function (punktID, i) {
                var p = dataObject.punkte.findBy('id', punktID);
                xWerte.push(Number(p.x));
                yWerte.push(Number(p.y));
                zWerte.push(Number(p.z));
                var punkt = self.get('store').push({
                  data: {
                    id: punktID,
                    type: 'punkt',
                    attributes: p
                  }
                });
                punkte.pushObject(punkt); // console.log('... eingelesen');
              }); // var translation = self.get('store').push({
              //   data: {
              //     id: item.id,
              //     type: 'translation',
              //     attributes: dataObject.punkte.findBy('id', dataObject.bauteile.findBy('id', item.id).point.objectAt(0))
              //   }
              // });

              self.setMinMaxWerte("x", xWerte);
              self.setMinMaxWerte("y", yWerte);
              self.setMinMaxWerte("z", zWerte);
            } // const verschiebung = self.get('store').push({
            //   data: {
            //     id: item.id,
            //     type: 'translation',
            //     attributes: dataObject.punkte.findBy('id', dataObject.bauteile.findBy('id', item.id).point.objectAt(0))
            //   }
            // });


            if (typeof dataObject.richtungsvektoren.findBy('id', dataObject.bauteile.findBy('id', item.id).richtungsvektor) === "undefined") {// console.log('RICHTUNGSVEKTOR für Objekt nicht vorhanden');
            } else {
              richtungsvektor = self.get('store').push({
                data: {
                  id: item.id,
                  type: 'richtungsvektor',
                  attributes: dataObject.richtungsvektoren.findBy('id', dataObject.bauteile.findBy('id', item.id).richtungsvektor)
                }
              });
            }

            if (typeof dataObject.ansichtsvektoren.findBy('id', dataObject.bauteile.findBy('id', item.id).offsetVektorAnsichtXY) === "undefined") {// console.log('offsetVektorAnsichtXY für Objekt nicht vorhanden');
            } else {
              ansichtsvektor1 = self.get('store').push({
                data: {
                  id: dataObject.bauteile.findBy('id', item.id).offsetVektorAnsichtXY,
                  type: 'ansichtsvektoren',
                  attributes: dataObject.ansichtsvektoren.findBy('id', dataObject.bauteile.findBy('id', item.id).offsetVektorAnsichtXY)
                }
              });
            }

            if (typeof dataObject.ansichtsvektoren.findBy('id', dataObject.bauteile.findBy('id', item.id).offsetVektorAnsichtXZ) === "undefined") {// console.log('offsetVektorAnsichtXZ für Objekt nicht vorhanden');
            } else {
              ansichtsvektor2 = self.get('store').push({
                data: {
                  id: dataObject.bauteile.findBy('id', item.id).offsetVektorAnsichtXZ,
                  type: 'ansichtsvektoren',
                  attributes: dataObject.ansichtsvektoren.findBy('id', dataObject.bauteile.findBy('id', item.id).offsetVektorAnsichtXZ)
                }
              });
            }

            if (typeof dataObject.ansichtsvektoren.findBy('id', dataObject.bauteile.findBy('id', item.id).offsetVektorAnsichtYZ) === "undefined") {// console.log('offsetVektorAnsichtYZ für Objekt nicht vorhanden');
            } else {
              ansichtsvektor3 = self.get('store').push({
                data: {
                  id: dataObject.bauteile.findBy('id', item.id).offsetVektorAnsichtYZ,
                  type: 'ansichtsvektoren',
                  attributes: dataObject.ansichtsvektoren.findBy('id', dataObject.bauteile.findBy('id', item.id).offsetVektorAnsichtYZ)
                }
              });
            }

            if (typeof dataObject.orthovektoren.findBy('id', dataObject.bauteile.findBy('id', item.id).orthovektor) === "undefined") {// console.log('ORTHOVEKTOREN für Objekt nicht vorhanden');
            } else {
              orthovektor = self.get('store').push({
                data: {
                  id: item.id,
                  type: 'orthovektor',
                  attributes: dataObject.orthovektoren.findBy('id', dataObject.bauteile.findBy('id', item.id).orthovektor)
                }
              });
            }

            if (typeof dataObject.verbindungsmittel.findBy('id', dataObject.bauteile.findBy('id', item.id).verbindungsmittel) === "undefined") {// console.log('VERBINDUNGSMITTEL für Objekt nicht vorhanden');
            } else {
              verbindungsmittel = self.get('store').push({
                data: {
                  id: item.id,
                  type: 'screw-property',
                  attributes: dataObject.verbindungsmittel.findBy('id', dataObject.bauteile.findBy('id', item.id).verbindungsmittel)
                }
              });
            }

            if (typeof dataObject.masskette.findBy('id', item.id) === "undefined") {// console.log('MASSKETTE für Objekt nicht vorhanden');
            } else {
              masskette = self.get('store').push({
                data: {
                  id: item.id,
                  type: 'masskette',
                  attributes: dataObject.masskette.findBy('id', item.id)
                }
              });
            }

            if (typeof dataObject.lagewinkel.findBy('id', dataObject.bauteile.findBy('id', item.id).lagewinkel) === "undefined") {// console.log('LAGEWINKEL für Objekt nicht vorhanden');
            } else {
              lagewinkel = self.get('store').push({
                data: {
                  id: item.id,
                  type: 'lagewinkel',
                  attributes: dataObject.lagewinkel.findBy('id', dataObject.bauteile.findBy('id', item.id).lagewinkel)
                }
              });
            }

            if (typeof dataObject.platte.findBy('id', item.id) === "undefined") {// console.log('PLATTE für Objekt nicht vorhanden');
            } else {
              platte = self.get('store').push({
                data: {
                  id: item.id,
                  type: 'platte',
                  attributes: dataObject.platte.findBy('id', item.id)
                }
              });
            }

            diffusecolor = self.get('store').push({
              data: {
                id: item.id,
                type: 'diffusecolor',
                attributes: {
                  id: newID,
                  rot: x3d.get('bauteile').findBy('id', newID).get('diffusecolors').objectAt(0).get('rot'),
                  gruen: x3d.get('bauteile').findBy('id', newID).get('diffusecolors').objectAt(0).get('gruen'),
                  blau: x3d.get('bauteile').findBy('id', newID).get('diffusecolors').objectAt(0).get('blau')
                }
              }
            });
            appearance = self.get('store').push({
              data: {
                id: item.id,
                type: 'appearance',
                attributes: {
                  id: newID,
                  transparency: x3d.get('bauteile').findBy('id', newID).get('appearances').objectAt(0).get('transparency')
                }
              }
            });
            var bauteil;

            if (item.typ === "verbindungsmittel") {
              // console.log('verbindungsmittel: '+item.id);
              // bauteil = self.get('applicationController').get('store').createRecord('bauteil', {
              //       id: item.id,
              //       typ: item.typ,
              //       // punkt: [dataObject.punkte.findBy('id', dataObject.bauteile.findBy('id', item.id).point.objectAt(0))],
              //       translations: [punkt],
              //       rotations: [rotation],
              //       richtungsvektor: [richtung],
              //       appearances: [appearance],
              //       diffusecolors: [diffusecolor],
              //       screwProperties:[eigenschaften]
              // });
              var bauteil = self.get('applicationController').get('store').createRecord('bauteil', {
                id: item.id,
                typ: item.typ,
                punkt: punkte,
                // translations: [translation],
                // rotations: [punkt],
                appearances: [appearance],
                diffusecolors: [diffusecolor],
                screwProperties: [verbindungsmittel],
                richtungsvektor: [richtungsvektor],
                lagewinkel: [lagewinkel]
              });
            } else if (item.typ === "platte") {
              // console.log('platte: '+item.id);
              var bauteil = self.get('applicationController').get('store').createRecord('bauteil', {
                id: item.id,
                typ: item.typ,
                punkt: punkte,
                // translations: ["null"],
                // rotations: ["null"],
                appearances: [appearance],
                diffusecolors: [diffusecolor],
                richtungsvektor: [richtungsvektor],
                // orthovektor: [orthovektor],
                platte: [platte]
              });
            } else if (item.typ === "masskette") {
              // console.log('masskette: '+item.id);
              // console.log(punkt);
              // console.log(appearance);
              // console.log(diffusecolor);
              // console.log(richtungsvektor);
              // console.log(masskette);
              // console.log(ansichtsvektor1);
              // console.log(ansichtsvektor2);
              // console.log(ansichtsvektor3);
              var bauteil = self.get('applicationController').get('store').createRecord('bauteil', {
                id: item.id,
                typ: item.typ,
                punkt: punkte,
                // translations: ["null"],
                // rotations: ["null"],
                appearances: [appearance],
                diffusecolors: [diffusecolor],
                richtungsvektor: [richtungsvektor],
                // orthovektor: [item.orthovektor],
                masskette: [masskette],
                ansichtsvektoren: [ansichtsvektor1, ansichtsvektor2, ansichtsvektor3]
              });
            }

            x3d.get('bauteile').pushObject(bauteil);
          }); //Bauteile

          x3d.set('schraubenlisteAktualisiert', !x3d.get('schraubenlisteAktualisiert'));
          x3d.set('aktuellerSchraubenTyp', 'verbindungsmittel');
          x3d.set('ergebnisGeladen', true);
          self.get('applicationController').zentriertObjekt();
          self.get('applicationController').zentriertObjekt();
        }); // self.set('reload', false);

      } // ENDE if

    },
    proceedInput: function (event) {
      console.log("verbindungsmittel proceedInput");
      var self = this;
      var selectedScrew = null;

      if (self.get('keineSchraubenliste') === false) {
        selectedScrew = self.get('selectedSrewArtNr');
        ;
      }

      var applicationdata = self.get('applicationController').get('model').application.objectAt(0);
      applicationdata.set('selectedScrew', selectedScrew);
      self.set('loading', true);
      console.log('cid: ' + self.calcid);
      console.log('artnr: ' + selectedScrew);
      var server = self.get('applicationController').get('server');

      _jquery.default.ajax({
        type: "POST",
        url: server + "m12/DE/getKompaktergebnis/" + self.get('superController').erzeugeAufrufzusatz(true),
        data: {
          cid: self.calcid,
          artnr: selectedScrew
        }
      }).done(function (data, statusText, xhr) {
        self.get('superController').logaufruf("getKompaktergebnis", xhr.status);
        console.log(data);
        self.get('downloadcenterController').set('pdfErstellt', false);
        var html = data;
        var bEnd, bStart;
        bStart = html.indexOf("<div id='headline'");
        bEnd = html.indexOf("</body");
        var body = html.slice(bStart, bEnd);
        self.get('ergebnisController').set('kompaktergebnis', body.toString());
        self.get('ergebnisController').set('pdfErstellt', false);
        self.get('ergebnisController').setKompaktergebnis();
        self.transitionToRoute('/ergebnis');
        self.set('loading', false);
      });
    },

    logNode(event) {
      var self = this;
      console.log(self.selectedNodes);
      console.log(self.selectedNodes[0].text);
    },

    urListe: [],
    sListe: [],
    nurValideSchrauben: false,
    sortierUndFilterReihenfolge: [],
    params: ['VM_Typ', 'Kopfform', 'd_1', 'l_s'],
    sListeTrigger: false,
    upDownTrigger: false,
    mindestensEinParameterAusgewahltListe: [],
    parameterObjekte: [],
    parameterObjekteTrigger: false,
    // parameterValues: [],
    schraubenliste: Ember.computed('sListeTrigger', function () {
      return this.get('sListe');
    }),
    watchNurValideSchrauben: Ember.observer('nurValideSchrauben', function () {
      var self = this;
      self.set('sListe', self.createSchraubenliste(this.get('lasteinwirkungController').get('verbindungsmittelliste')));
      self.set('sListeTrigger', !this.get('sListeTrigger'));
    }),
    setParameterValues: function (_liste, _param) {
      var self = this;
      var tempObjekt = [];
      this.get('mindestensEinParameterAusgewahltListe').forEach((item, i) => {
        tempObjekt.push(self.get('parameterObjekte').findBy('id', item));
      });
      self.set('parameterObjekte', []);

      if (tempObjekt.length !== 0) {
        self.parameterObjekte = tempObjekt;
      }

      self.get('params').forEach((_param, i) => {
        if (typeof this.get('parameterObjekte').findBy('id', _param) === 'undefined') {
          self.get('parameterObjekte').pushObject({
            id: _param,
            values: []
          });
        }

        _liste.forEach((_schraube, i) => {
          if (typeof this.get('parameterObjekte').findBy('id', _param).values.findBy('id', _schraube[_param]) === 'undefined') {
            self.get('parameterObjekte').findBy('id', _param).values.push({
              id: _schraube[_param],
              checked: true,
              visible: true
            });
          }
        });
      });
      self.set('parameterObjekteTrigger', !self.get('parameterObjekteTrigger'));
    },
    parameterObjekteListe: Ember.computed('parameterObjekteTrigger', function () {
      var self = this;
      return self.get('parameterObjekte');
    }),

    sortUp(_param) {
      this.set('upDownTrigger', !this.get('upDownTrigger'));

      if (isNaN(parseFloat(this.get('schraubenliste').objectAt(0)[_param])) === true) {
        this.set('sListe', this.get('schraubenliste').sort(function (a, b) {
          var nameA = b[_param].toUpperCase(); // Groß-/Kleinschreibung ignorieren


          var nameB = a[_param].toUpperCase(); // Groß-/Kleinschreibung ignorieren


          if (nameA < nameB) {
            return -1;
          }

          if (nameA > nameB) {
            return 1;
          } // Namen müssen gleich sein


          return 0;
        }));
      } else {
        this.set('sListe', this.get('schraubenliste').sort(function (a, b) {
          return parseFloat(b[_param]) - parseFloat(a[_param]);
        }));
      }

      this.set('sListeTrigger', !this.get('sListeTrigger'));
    },

    sortDown(_param) {
      this.set('upDownTrigger', !this.get('upDownTrigger'));

      if (isNaN(parseFloat(this.get('schraubenliste').objectAt(0)[_param])) === true) {
        this.set('sListe', this.get('schraubenliste').sort(function (a, b) {
          var nameA = a[_param].toUpperCase(); // Groß-/Kleinschreibung ignorieren


          var nameB = b[_param].toUpperCase(); // Groß-/Kleinschreibung ignorieren


          if (nameA < nameB) {
            return -1;
          }

          if (nameA > nameB) {
            return 1;
          } // Namen müssen gleich sein


          return 0;
        }));
      } else {
        this.set('sListe', this.get('schraubenliste').sort(function (a, b) {
          return parseFloat(a[_param]) - parseFloat(b[_param]);
        }));
      }

      this.set('sListeTrigger', !this.get('sListeTrigger'));
    },

    toggleDisplayNone(_param) {
      this.set("hide" + _param, !this.get("hide" + _param));
    },

    toggkleAllChecked(_param, _value) {
      var self = this;
      self.get('parameterObjekte').forEach((parameter, i) => {
        if (parameter.id === _param) {
          parameter.values.forEach((wert, i) => {
            Ember.set(wert, 'checked', _value);
          });
        }
      });
    },

    controllerfilter(_param, _value, _checked) {
      var self = this;
      var sliste = this.get('nurValideSchrauben') === false ? this.get('lasteinwirkungController').get('verbindungsmittelliste') : this.get('schraubenliste');

      if (_checked === true) {
        self.set('sortierUndFilterReihenfolge', _jquery.default.grep(self.get('sortierUndFilterReihenfolge'), function (element, i) {
          if (element.id === _param && element.value === _value) {} else {
            return element;
          }
        }));
      } else {
        self.get('sortierUndFilterReihenfolge').pushObject({
          id: _param,
          value: _value
        });
      }

      this.set('sListe', self.createSchraubenliste(this.get('lasteinwirkungController').get('verbindungsmittelliste')));
      this.set('sListeTrigger', !this.get('sListeTrigger'));
    },

    createSchraubenliste: function (_sliste) {
      var self = this;

      if (self.get('nurValideSchrauben') === true) {
        _sliste = _jquery.default.grep(_sliste, function (element, i) {
          return element.VM_Okay === "True";
        });
      }

      var tempSListe = _sliste;
      self.get('parameterObjekte').forEach((parameter, i) => {
        parameter.values.forEach((wert, i) => {
          Ember.set(wert, 'visible', false);

          if (wert.checked === false) {
            _sliste = _jquery.default.grep(_sliste, function (schraube, i) {
              return schraube[parameter.id] !== wert.id;
            });
          }
        });
      });
      self.get('parameterObjekte').forEach((parameter, i) => {
        parameter.values.forEach((wert, i) => {
          if (typeof _sliste.findBy(parameter.id, wert.id) !== "undefined") {
            Ember.set(wert, 'visible', true);
          }
        });
      });
      self.get('sortierUndFilterReihenfolge').forEach((element, i) => {
        if (typeof tempSListe.findBy(element.id, element.value) !== "undefined") {
          Ember.set(self.get('parameterObjekte').findBy('id', element.id).values.findBy('id', element.value), 'visible', true);
        }
      });
      return _sliste;
    },
    setKompaktergebnisHeight: function () {
      var self = this;
      var y = Number((0, _jquery.default)("canvas").height());
      self.set('kompaktergebnisHeight', y - 47 + "px");
    },
    setMinMaxWerte: function (bezeichner, array) {
      var self = this;
      var x3d = self.get('applicationController').get('model').x3ddefault.objectAt(0);
      var dimensionen = x3d.get('dimensionen');
      array.sort(function (a, b) {
        return a - b;
      });
      dimensionen.findBy('name', 'ergebnisgrafikMinialWerte').set(bezeichner, Number(array[0]));
      dimensionen.findBy('name', 'ergebnisgrafikMaximalWerte').set(bezeichner, Number(array[array.length - 1]));
    }
  }, (_applyDecoratedDescriptor(_obj, "controllerSetSelectedScrew", [Ember._action], Object.getOwnPropertyDescriptor(_obj, "controllerSetSelectedScrew"), _obj), _applyDecoratedDescriptor(_obj, "logNode", [Ember._action], Object.getOwnPropertyDescriptor(_obj, "logNode"), _obj), _applyDecoratedDescriptor(_obj, "sortUp", [Ember._action], Object.getOwnPropertyDescriptor(_obj, "sortUp"), _obj), _applyDecoratedDescriptor(_obj, "sortDown", [Ember._action], Object.getOwnPropertyDescriptor(_obj, "sortDown"), _obj), _applyDecoratedDescriptor(_obj, "toggleDisplayNone", [Ember._action], Object.getOwnPropertyDescriptor(_obj, "toggleDisplayNone"), _obj), _applyDecoratedDescriptor(_obj, "toggkleAllChecked", [Ember._action], Object.getOwnPropertyDescriptor(_obj, "toggkleAllChecked"), _obj), _applyDecoratedDescriptor(_obj, "controllerfilter", [Ember._action], Object.getOwnPropertyDescriptor(_obj, "controllerfilter"), _obj)), _obj));

  _exports.default = _default;
});