define("m12-2020/templates/components/screw-tablehead", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Ndl1AZGk",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"schraubentabelle\"],[8],[0,\"\\n  \"],[7,\"div\",false],[12,\"class\",\"tableheadEintrag\"],[3,\"on\",[\"click\",[23,0,[\"toggleAuswahl\"]]]],[8],[0,\" \"],[1,[28,\"t\",[[23,0,[\"parameter\"]]],null],true],[9],[0,\"\\n  \"],[7,\"div\",true],[11,\"class\",[29,[\"tabellenkopf \",[28,\"if\",[[23,0,[\"hideAuswahl\"]],\"displayNone\"],null]]]],[8],[0,\"\\n    \"],[7,\"span\",false],[3,\"on\",[\"click\",[28,\"fn\",[[23,0,[\"sortDown\"]],[24,[\"parameter\"]]],null]]],[8],[0,\"↑\"],[9],[7,\"br\",true],[8],[9],[0,\"\\n    \"],[7,\"span\",false],[3,\"on\",[\"click\",[28,\"fn\",[[23,0,[\"sortUp\"]],[24,[\"parameter\"]]],null]]],[8],[0,\"↓\"],[9],[7,\"br\",true],[8],[9],[0,\"\\n    \"],[5,\"input\",[[12,\"name\",\"alleschrauben\"]],[[\"@type\",\"@checked\"],[\"checkbox\",[23,0,[\"alleschrauben\"]]]]],[0,\" \"],[1,[28,\"t\",[\"allesauswaehlen\"],null],false],[0,\"\\n    \"],[7,\"hr\",true],[10,\"class\",\"strich\"],[8],[9],[0,\"\\n    \"],[5,\"multiselect-box\",[],[[\"@parameterobjekte\",\"@parameter\",\"@cfilter\"],[[22,\"parameterObjekteListe\"],[22,\"parameter\"],[23,0,[\"thfilter\"]]]]],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "m12-2020/templates/components/screw-tablehead.hbs"
    }
  });

  _exports.default = _default;
});