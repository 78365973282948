define("m12-2020/components/screw-tablehead", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var Multiselectbox = Ember.Component.extend((_obj = {
    tagName: 'th',
    layoutName: 'screw-tablerhead',
    attributeBindings: [],
    classNames: ['systemtablehead'],
    displayedArrow: "",
    hideUpArrow: false,
    hideDownArrow: false,
    hideAuswahl: true,
    alleschrauben: true,

    toggleAuswahl(e) {
      this.set("hideAuswahl", !this.get("hideAuswahl"));
    },

    sortUp(_param) {
      this.set('hiddenArrow', "Up");
      this.set('down', true);
      this.controllerSortUp(_param);
    },

    sortDown(_param) {
      this.set('hiddenArrow', "Down");
      this.controllerSortDown(_param);
    },

    thfilter(_parameter, _id, _checked) {
      this.cfilter(_parameter, _id, _checked);
    },

    thtoggkleAllChecked: Ember.observer('alleschrauben', function () {
      var self = this;
      this.cToggkleAllChecked(self.get('parameter'), self.get('alleschrauben'));
    })
  }, (_applyDecoratedDescriptor(_obj, "toggleAuswahl", [Ember._action], Object.getOwnPropertyDescriptor(_obj, "toggleAuswahl"), _obj), _applyDecoratedDescriptor(_obj, "sortUp", [Ember._action], Object.getOwnPropertyDescriptor(_obj, "sortUp"), _obj), _applyDecoratedDescriptor(_obj, "sortDown", [Ember._action], Object.getOwnPropertyDescriptor(_obj, "sortDown"), _obj), _applyDecoratedDescriptor(_obj, "thfilter", [Ember._action], Object.getOwnPropertyDescriptor(_obj, "thfilter"), _obj)), _obj));
  var _default = Multiselectbox;
  _exports.default = _default;
});