define("m12-2020/templates/ergebnis", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ahLscBMX",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"id\",\"subcontainer\"],[10,\"class\",\"kompaktergebnis\"],[8],[0,\"\\n  \"],[7,\"h3\",true],[10,\"class\",\"formheadline\"],[8],[0,\"\\n    \"],[1,[28,\"t\",[\"ergebnis\"],null],false],[0,\"\\n    \"],[7,\"button\",false],[12,\"class\",\"btn btn-default btn-xs\"],[3,\"on\",[\"click\",[28,\"fn\",[[23,0,[\"createPDF\"]],[24,[\"entitity\"]]],null]]],[8],[0,\"\\n      \"],[7,\"img\",true],[10,\"src\",\"assets/images/pdf.png\"],[8],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\\n  \"],[1,[22,\"kompaktergebnis\"],true],[0,\"\\n\\n  \"],[7,\"br\",true],[8],[9],[0,\"\\n\"],[4,\"if\",[[23,0,[\"pdfUnderConstruction\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"three-quarters-loader\"],[8],[0,\"\\n      Loading…\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[23,0,[\"pdfErstellt\"]]],null,{\"statements\":[[0,\"    \"],[7,\"center\",true],[8],[0,\"\\n      \"],[7,\"p\",true],[8],[0,\"\\n        \"],[7,\"a\",true],[11,\"href\",[29,[[22,\"pdfLink\"]]]],[10,\"target\",\"_blank\"],[8],[7,\"img\",true],[10,\"src\",\"assets/images/pdf_icon.png\"],[10,\"border\",\"0\"],[10,\"height\",\"64\"],[8],[9],[9],[0,\"\\n      \"],[9],[0,\"\\n      \"],[7,\"a\",true],[11,\"href\",[29,[[22,\"pdfLink\"]]]],[10,\"target\",\"_blank\"],[8],[1,[23,0,[\"pdfFileName\"]],true],[9],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "m12-2020/templates/ergebnis.hbs"
    }
  });

  _exports.default = _default;
});