define("m12-2020/controllers/traeger", ["exports", "m12-2020/mixins/object-validator"], function (_exports, _objectValidator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = Ember.Controller.extend(_objectValidator.default, (_obj = {
    debug: true,
    openFromFile: false,
    c26: false,
    displayErrors: true,
    material: undefined,
    honk: null,
    intl: Ember.inject.service(),
    ttHoeheHT: Ember.computed('intl.locale', {
      get(key) {
        return this.get('intl').t('wertebereich') + " 10 - 999.9 [cm]";
      },

      set(key, value) {
        return value;
      }

    }),
    ttBreiteHT: Ember.computed('intl.locale', {
      get(key) {
        return this.get('intl').t('wertebereich') + " 5 - 999.9 [cm]";
      },

      set(key, value) {
        return value;
      }

    }),
    ttHoeheNT: Ember.computed('intl.locale', {
      get(key) {
        return this.get('intl').t('wertebereich') + " 10 - 999.9 [cm]";
      },

      set(key, value) {
        return value;
      }

    }),
    ttBreiteNT: Ember.computed('intl.locale', {
      get(key) {
        return this.get('intl').t('wertebereich') + " 5 - 999.9 [cm]";
      },

      set(key, value) {
        return value;
      }

    }),
    applicationController: Ember.inject.controller('application'),
    auflagerController: Ember.inject.controller('auflager'),
    optionenController: Ember.inject.controller('optionen'),
    lasteinwirkungController: Ember.inject.controller('lasteinwirkung'),
    superController: Ember.inject.controller('supercontroller'),
    downloadcenterController: Ember.inject.controller('downloadcenter'),
    hauptnebentraeger: '',
    htbreite: "",
    hthoehe: "",
    toolTip: "test tooltip",
    ntfkl: 5,
    ntholz: 0,
    httraeger: true,
    validations: {
      htbreite: {
        numericality: {
          greaterThanOrEqualTo: 5,
          lessThanOrEqualTo: 999.9,
          message: "!"
        }
      },
      hthoehe: {
        numericality: {
          greaterThanOrEqualTo: 10,
          lessThanOrEqualTo: 999.9,
          message: "!"
        }
      }
    },
    ntmaterial: [{
      name: "Vollholz Nadelholz",
      ind: 0
    }, {
      name: "Brettschichtholz homogen",
      ind: 1
    }, {
      name: "Brettschichtholz kombiniert",
      ind: 13
    }],
    fkl_vh: [{
      fklind: 0,
      fkl: "C14"
    }, {
      fklind: 1,
      fkl: "C16"
    }, {
      fklind: 2,
      fkl: "C18"
    }, {
      fklind: 3,
      fkl: "C20"
    }, {
      fklind: 4,
      fkl: "C22"
    }, {
      fklind: 5,
      fkl: "C24"
    }, {
      fklind: 6,
      fkl: "C27"
    }, {
      fklind: 7,
      fkl: "C30"
    }, {
      fklind: 8,
      fkl: "C35"
    }, {
      fklind: 9,
      fkl: "C40"
    }, {
      fklind: 10,
      fkl: "C45"
    }, {
      fklind: 11,
      fkl: "C50"
    }],
    fkl_bsh: [{
      fklind: 20,
      fkl: "GL24h"
    }, {
      fklind: 21,
      fkl: "GL28h"
    }, {
      fklind: 24,
      fkl: "GL30h"
    }, {
      fklind: 23,
      fkl: "GL32h"
    }],
    fkl_bsh_kombi: [{
      fklind: 30,
      fkl: "GL24c"
    }, {
      fklind: 31,
      fkl: "GL28c"
    }, {
      fklind: 34,
      fkl: "GL30c"
    }, {
      fklind: 33,
      fkl: "GL32c"
    }],
    ntfklarray: undefined,
    init: function () {
      this._super();
    },
    initialisierung: function () {
      this.setSelectFieldsContent();
      this.set('hauptnebentraeger', this.get('intl').t('bemessungslast').toString());
      this.send('validation', -1, {
        target: {
          name: "foobar"
        }
      });
    },
    setValues: function (values) {
      var self = this;

      if (self.debug) {
        console.log("values in bauteile: ");
        console.log(values);
      }

      var htbreiteEvent = {
        target: {
          name: "htbreite"
        }
      };
      var hthoeheEvent = {
        target: {
          name: "hthoehe"
        }
      };
      this.set('openFromFile', true);
      this.set('htbreite', values.TimberElement_b);
      this.setX3D(parseFloat(values.TimberElement_b), htbreiteEvent);
      this.set('hthoehe', values.TimberElement_h);
      this.setX3D(parseFloat(values.TimberElement_h), hthoeheEvent);
      this.set('ntholz', parseInt(values.MatKeyTimberElement));
      this.set('ntfkl', parseInt(values.FKLKeyTimberElement));
      this.send('validation');
      this.set('openFromFile', false);
    },
    setSelectFieldsContent: function () {
      var self = this;
      self.setMaterialien();
      self.setFKL();
    },
    setMaterialien: function () {
      var self = this;
      var indices = [0, 1, 13];
      self.set('ntmaterial', self.getSelectFieldContent('materialienDB', indices, self.get('ntholz')));
    },
    setFKL: function (indices) {
      var self = this;

      if (self.get('ntholz') === 1) {
        indices = [20, 21, 24, 22];
        self.set('ntfklarray', self.getSelectFieldContent('fkl_bsh', indices, self.get('ntfkl')));
        self.set('ntfkl', 20);
      } else if (self.get('ntholz') === 13) {
        indices = [30, 31, 34, 32];
        self.set('ntfklarray', self.getSelectFieldContent('fkl_bsh_kombi', indices, self.get('ntfkl')));
        self.set('ntfkl', 30);
      } else {
        indices = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];
        self.set('ntfklarray', self.getSelectFieldContent('fkl_vh', indices, self.get('ntfkl')));
        self.set('ntfkl', 5);
      }
    },
    getSelectFieldContent: function (db, indexes, selectedValue) {
      var self = this;
      var tmp = [];
      var dbContent = self.getDBContent(db);

      for (var i = 0; i < indexes.length; i++) {
        for (var k = 0; k < dbContent.length; k++) {
          if (dbContent[k].id === indexes[i]) {
            tmp.push({
              id: dbContent[k].id,
              name: this.get('intl').t(dbContent[k].name),
              selected: selectedValue === dbContent[k].id ? true : false
            });
          }
        }
      }

      return tmp;
    },
    getDBContent: function (db) {
      var dbContent = [];

      switch (db) {
        case 'materialienDB':
          dbContent = materialienDB.materialien_de;
          break;

        case 'fkl_vh':
          dbContent = fklDB_new.fkl_vh.fklassen;
          break;

        case 'fkl_bsh':
          dbContent = fklDB_new.fkl_bsh.fklassen;
          break;

        case 'fkl_bsh_kombi':
          dbContent = fklDB_new.fkl_bsh_kombi.fklassen;
          break;
      }

      return dbContent;
    },
    getValueFromSelectField: function (contentArray, index) {
      var tmp = "";
      var len = contentArray.length;

      for (var i = 0; i < len; i++) {
        if (contentArray[i].id === index) {
          tmp = contentArray[i].name;
        }
      }

      return tmp;
    },
    watchNumericalInputs: Ember.observer('htbreite', 'hthoehe', function () {
      if (!this.openFromFile) {
        if (this.get('htbreite').toString().indexOf(",") !== -1) {
          this.set('htbreite', this.get('htbreite').toString().replace(",", "."));
        }

        if (this.get('hthoehe').toString().indexOf(",") !== -1) {
          this.set('hthoehe', this.get('hthoehe').toString().replace(",", "."));
        }
      }
    }),

    setNtMaterial(value) {
      this.set('ntholz', Number(value.target.value));
      this.setFKL();
    },

    setNtFKL(value) {
      this.set('ntfkl', Number(value.target.value));
    },

    watchComboboxes: Ember.observer('ntholz', 'ntfkl', function () {
      var self = this;
      self.get('superController').resetVerbindungsmittel();
      var traeger = this.get('applicationController').get('model').traeger.objectAt(0);
      traeger.set('MatKeyTimberElement', this.get('ntholz'));
      traeger.set('FKLKeyTimberElement', this.get('ntfkl')); // self.get('lasteinwirkungController').setnklarray();
      // self.get('lasteinwirkungController').send('validation', self.get('lasteinwirkungController').get('vorbelastung'), {target: {name: "vorbelastung"}});
    }),
    setX3D: function (value, event) {
      var x3d = this.get('applicationController').get('model').x3ddefault.objectAt(0);
      var bauteile = this.get('applicationController').get('model').x3ddefault.objectAt(0).get('bauteile');
      var self = this;
      x3d.set('ergebnisGeladen', false);
      x3d.set('aktuellerSchraubenTyp', 'standardSchraube');
      x3d.set('schraubenlisteAktualisiert', !x3d.get('schraubenlisteAktualisiert'));
      value = parseFloat(value).toFixed(1);

      switch (event.target.name) {
        case 'htbreite':
          if (typeof self.get('errors').get("htbreite") === "undefined") {
            bauteile.findBy('id', 'traeger').get('boxsizes').objectAt(0).set('z', value);
            x3d.set('htBreiteEingetragen', true);
            self.get('auflagerController').set('ttBreiteA', self.get('intl').t('wertebereich') + " 0.1 - " + self.get('htbreite') + " [cm]");
            self.get('auflagerController').set('validations.abreite.numericality.lessThanOrEqualTo', Number(value));

            if (Number(self.get('auflagerController').get('abreite')) > Number(value)) {
              self.get('auflagerController').set('abreite', value);
              self.get('auflagerController').send('validation', value, {
                target: {
                  name: "abreite"
                }
              });
            }
          } else {
            x3d.set('htBreiteEingetragen', false);
          }

          break;

        case 'hthoehe':
          if (typeof self.get('errors').get("hthoehe") === "undefined") {
            bauteile.findBy('id', 'traeger').get('boxsizes').objectAt(0).set('y', value); // if(bauteile.findBy('id', 'auflager').get('erweiterteeigenschaften').objectAt(0).get('istendauflager')){
            //   bauteile.findBy('id', 'traeger').get('boxsizes').objectAt(0).set('x', 1.5*value);
            // }else{
            //   bauteile.findBy('id', 'traeger').get('boxsizes').objectAt(0).set('x', 3*value);
            // }

            self.get('optionenController').setGrenzwert("sicherheitsAbstand", value);
            x3d.set('htHoeheEingetragen', true);
          } else {
            x3d.set('htHoeheEingetragen', false);
          }

          break;
      }

      self.get('superController').setTraegerLaenge(bauteile);
      self.get('optionenController').setSchraubenlaenge();
      x3d.set('transformHelper', !x3d.get('transformHelper'));
      self.get('applicationController').zentriertObjekt();
    },

    validation(value, event) {
      var self = this;

      if (self.validate() === true) {
        self.set('displayErrors', false);
        self.get('applicationController').set('traegerInvalid', false);
        self.get('superController').resetVerbindungsmittel();
        var traeger = self.get('applicationController').get('model').traeger.objectAt(0);
        traeger.set('TimberElement_b', parseFloat(self.get('htbreite')).toFixed(1));
        traeger.set('TimberElement_h', parseFloat(self.get('hthoehe')).toFixed(1));
      } else {
        self.set('displayErrors', true);
        self.get('applicationController').set('traegerInvalid', true);
      }

      if (!self.openFromFile) {
        this.setX3D(value, event);
      }
    },

    htbreiteIsSelected() {
      var htbreiteaktiv = this.get('applicationController').get('model').x3ddefault.objectAt(0).get('htbreiteAktiv');
      this.get('applicationController').get('model').x3ddefault.objectAt(0).set('htbreiteAktiv', !htbreiteaktiv);

      if (this.get('htbreite') !== "") {
        var htbreitework = parseFloat(this.get('htbreite').toString().replace(",", "."));
        var htbreiterounded = htbreitework.toFixed(1);
        this.set('htbreite', htbreiterounded);
        this.send('validation', htbreiterounded, {
          target: {
            name: "htbreite"
          }
        });
      }

      if (htbreiteaktiv === false) {
        document.getElementsByName('htbreite')[0].setSelectionRange(0, this.get('htbreite').length);
      }
    },

    hthoeheIsSelected() {
      var hthoeheaktiv = this.get('applicationController').get('model').x3ddefault.objectAt(0).get('hthoeheAktiv');
      this.get('applicationController').get('model').x3ddefault.objectAt(0).set('hthoeheAktiv', !hthoeheaktiv);

      if (this.get('hthoehe') !== "") {
        var hthoehework = parseFloat(this.get('hthoehe').toString().replace(",", "."));
        var hthoeherounded = hthoehework.toFixed(1);
        this.set('hthoehe', hthoeherounded);
        this.send('validation', hthoeherounded, {
          target: {
            name: "hthoehe"
          }
        });
      }

      if (hthoeheaktiv === false) {
        document.getElementsByName('hthoehe')[0].setSelectionRange(0, this.get('hthoehe').length);
      }
    }

  }, (_applyDecoratedDescriptor(_obj, "setNtMaterial", [Ember._action], Object.getOwnPropertyDescriptor(_obj, "setNtMaterial"), _obj), _applyDecoratedDescriptor(_obj, "setNtFKL", [Ember._action], Object.getOwnPropertyDescriptor(_obj, "setNtFKL"), _obj), _applyDecoratedDescriptor(_obj, "validation", [Ember._action], Object.getOwnPropertyDescriptor(_obj, "validation"), _obj), _applyDecoratedDescriptor(_obj, "htbreiteIsSelected", [Ember._action], Object.getOwnPropertyDescriptor(_obj, "htbreiteIsSelected"), _obj), _applyDecoratedDescriptor(_obj, "hthoeheIsSelected", [Ember._action], Object.getOwnPropertyDescriptor(_obj, "hthoeheIsSelected"), _obj)), _obj));

  _exports.default = _default;
});